export default [
  // usulan-pribadi
  {
    path: '/usulan/pribadi',
    name: 'usulan-pribadi',
    component: () => import('@/views/usulan/Pribadi.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Pribadi',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // usulan-status-kepegawaian
  {
    path: '/usulan/status-kepegawaian',
    name: 'usulan-status-kepegawaian',
    component: () => import('@/views/usulan/StatusKepegawaian.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Status Kepegawaian',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // usulan-pangkat
  {
    path: '/usulan/pangkat',
    name: 'usulan-pangkat',
    component: () => import('@/views/usulan/Pangkat.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Pangkat',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // usulan-jabatan
  {
    path: '/usulan/jabatan',
    name: 'usulan-jabatan',
    component: () => import('@/views/usulan/Jabatan.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Jabatan',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // usulan-keluarga
  {
    path: '/usulan/keluarga',
    name: 'usulan-keluarga',
    component: () => import('@/views/usulan/Keluarga.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Keluarga',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // usulan-catatan-hukuman
  {
    path: '/usulan/catatan-hukuman',
    name: 'usulan-catatan-hukuman',
    component: () => import('@/views/usulan/CatatanHukuman.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Catatan Hukuman',
          active: true,
        },
      ],
      pegawai: true,
      pengelola: false,
    },
  },
  // usulan-pendidikan
  {
    path: '/usulan/pendidikan',
    name: 'usulan-pendidikan',
    component: () => import('@/views/usulan/Pendidikan.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Pendidikan',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // usulan-diklat
  {
    path: '/usulan/diklat',
    name: 'usulan-diklat',
    component: () => import('@/views/usulan/Diklat.vue'),
    meta: {
      pageTitle: 'Usulan',
      breadcrumb: [
        {
          text: 'Perubahan Data > Diklat',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
]
