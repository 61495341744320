import Client from '@/repositories/endpoints/hrisApi'

const path = '/hris_djpb'
const resource = '/_proc/pegawai_grade'
const request = `${path}${resource}`

export default {
  // jenis = angka, huruf, lengkap
  // callproc({'nip': xxx, 'jenis' : ''})
  callproc(payload) {
    const payloadDecode = JSON.parse(payload)
    // console.log(payloadDecode)
    const param = {
      params: [{
        name: 'v_nip',
        param_type: 'IN',
        value: payloadDecode.nip,
        type: 'varchar',
        length: 18,
      },
      {
        name: 'v_jenis',
        param_type: 'IN',
        value: payloadDecode.jenis,
        type: 'varchar',
        length: 7,
      }],
      schema: {
        _field_name_: '',
      },
      wrapper: 'resource',
      returns: '',
    }
    return Client.post(`${request}`, param)
  },
  // MANY OTHER ENDPOINT RELATED STUFFS
}
