import Repository from '@/repositories/factories/hrisDjpbFactory'

const pegawaiFoto = Repository.get('displayUnitPegawaiFoto')

export default {
  state: {
    pegawaiProfile: [],
  },
  getters: {
    thisPegawaiProfile: state => state.pegawaiProfile,
  },
  actions: {
    async getPegawaiFoto({ commit }, payload) {
      commit('LOAD_FOTO', await pegawaiFoto.getPayload(payload))
    },
  },
  mutations: {
    INITIALIZE_PROFILE: state => {
      if (localStorage.getItem('users_storage')) {
        const data = JSON.parse(localStorage.getItem('users_storage'))
        console.log('PROFILE storage', data)
        state.pegawaiProfile = data
        console.log('INITIALIZE_PROFILE state', state.pegawaiProfile)
      }
    },
    LOAD_FOTO: (state, res) => {
      const isDataAvailable = res.data && res.data.resource.length
      const dataResource = isDataAvailable ? res.data.resource[0] : {
        error: {
          code: 404, context: null, message: 'Invalid Credentials', status_code: 404,
        },
      }
      if (localStorage.getItem('users_storage')) {
        const data = JSON.parse(localStorage.getItem('users_storage'))
        const newdata = Object.assign(data, dataResource)
        state.usersPbn = newdata
        state.pegawaiProfile = newdata
        localStorage.setItem('users_storage', JSON.stringify(newdata))
      }
    },

  },
}
