import userData from '@/repositories/repository/pbnOpen/userData'
import dataAlamat from '@/repositories/repository/pbnOpen/dataAlamat'
import dataAlamatPensiun from '@/repositories/repository/pbnOpen/dataAlamatPensiun'
import dataCuti from '@/repositories/repository/pbnOpen/dataCuti'
import dataCutiNote from '@/repositories/repository/pbnOpen/dataCutiNote'
import dataCutiNum from '@/repositories/repository/pbnOpen/dataCutiNum'
import dataCutiStatus from '@/repositories/repository/pbnOpen/dataCutiStatus'
import dataDiklat from '@/repositories/repository/pbnOpen/dataDiklat'
import dataDp3 from '@/repositories/repository/pbnOpen/dataDp3'
import dataDp3Detil from '@/repositories/repository/pbnOpen/dataDp3Detil'
import dataEdu from '@/repositories/repository/pbnOpen/dataEdu'
import dataEduLast from '@/repositories/repository/pbnOpen/dataEduLast'
import dataEduProgress from '@/repositories/repository/pbnOpen/dataEduProgress'
import dataEduReview from '@/repositories/repository/pbnOpen/dataEduReview'
import dataEmergency from '@/repositories/repository/pbnOpen/dataEmergency'
import dataEmp from '@/repositories/repository/pbnOpen/dataEmp'
import dataFileDossier from '@/repositories/repository/pbnOpen/dataFileDossier'
import dataFileUpload from '@/repositories/repository/pbnOpen/dataFileUpload'
import dataHeader from '@/repositories/repository/pbnOpen/dataHeader'
import dataIdCard from '@/repositories/repository/pbnOpen/dataIdCard'
import dataJabatan from '@/repositories/repository/pbnOpen/dataJabatan'
import dataKeluarga from '@/repositories/repository/pbnOpen/dataKeluarga'
import dataKeluargaLajang from '@/repositories/repository/pbnOpen/dataKeluargaLajang'
import dataKgbDokumen from '@/repositories/repository/pbnOpen/dataKgbDokumen'
import dataKgbHeaderFooter from '@/repositories/repository/pbnOpen/dataKgbHeaderFooter'
import dataKgbNum from '@/repositories/repository/pbnOpen/dataKgbNum'
import dataKgbPropose from '@/repositories/repository/pbnOpen/dataKgbPropose'
import dataNama from '@/repositories/repository/pbnOpen/dataNama'
import dataPangkat from '@/repositories/repository/pbnOpen/dataPangkat'
import dataPangkatInpassing from '@/repositories/repository/pbnOpen/dataPangkatInpassing'
import dataPendi from '@/repositories/repository/pbnOpen/dataPendi'
import dataPenghargaan from '@/repositories/repository/pbnOpen/dataPenghargaan'
import dataPic from '@/repositories/repository/pbnOpen/dataPic'
import dataPlacementReq from '@/repositories/repository/pbnOpen/dataPlacementReq'
import dataPropose from '@/repositories/repository/pbnOpen/dataPropose'
import dataProposeDelete from '@/repositories/repository/pbnOpen/dataProposeDelete'
import dataProposeDiklat from '@/repositories/repository/pbnOpen/dataProposeDiklat'
import dataProposeEdu from '@/repositories/repository/pbnOpen/dataProposeEdu'
import dataProposeEmp from '@/repositories/repository/pbnOpen/dataProposeEmp'
import dataProposeHukuman from '@/repositories/repository/pbnOpen/dataProposeHukuman'
import dataProposeJabatan from '@/repositories/repository/pbnOpen/dataProposeJabatan'
import dataProposeKeluarga from '@/repositories/repository/pbnOpen/dataProposeKeluarga'
import dataProposeDp3Detil from '@/repositories/repository/pbnOpen/dataProposeDp3Detil'
import dataProposePangkat from '@/repositories/repository/pbnOpen/dataProposePangkat'
import dataProposePenghargaan from '@/repositories/repository/pbnOpen/dataProposePenghargaan'
import dataProposeReject from '@/repositories/repository/pbnOpen/dataProposeReject'
import dataProposeSeminar from '@/repositories/repository/pbnOpen/dataProposeSeminar'
import dataProposeSlks from '@/repositories/repository/pbnOpen/dataProposeSlks'
import dataProposeStatus from '@/repositories/repository/pbnOpen/dataProposeStatus'
import dataProposeUser from '@/repositories/repository/pbnOpen/dataProposeUser'
import dataReportDefault from '@/repositories/repository/pbnOpen/dataReportDefault'
import dataReportType from '@/repositories/repository/pbnOpen/dataReportType'
import dataReqruitment from '@/repositories/repository/pbnOpen/dataReqruitment'
import dataRequestApproval from '@/repositories/repository/pbnOpen/dataRequestApproval'
import dataRequestBase from '@/repositories/repository/pbnOpen/dataRequestBase'
import dataRequestDokInfo from '@/repositories/repository/pbnOpen/dataRequestDokInfo'
import dataRequestIjazah from '@/repositories/repository/pbnOpen/dataRequestIjazah'
import dataRequestIzinLuarNegeri from '@/repositories/repository/pbnOpen/dataRequestIzinLuarNegeri'
import dataRequestIzinLuarNegeriCancel from '@/repositories/repository/pbnOpen/dataRequestIzinLuarNegeriCancel'
import dataRequestKaris from '@/repositories/repository/pbnOpen/dataRequestKaris'
import dataSeminar from '@/repositories/repository/pbnOpen/dataSeminar'
import dataStatus from '@/repositories/repository/pbnOpen/dataStatus'
import dataUkpCover from '@/repositories/repository/pbnOpen/dataUkpCover'
import dataUkpDaerah from '@/repositories/repository/pbnOpen/dataUkpDaerah'
import dataUkpDokumen from '@/repositories/repository/pbnOpen/dataUkpDokumen'
import dataUkpFileUpload from '@/repositories/repository/pbnOpen/dataUkpFileUpload'
import dataUkpInfo from '@/repositories/repository/pbnOpen/dataUkpInfo'
import dataUkpJadi from '@/repositories/repository/pbnOpen/dataUkpJadi'
import dataUkpKirim from '@/repositories/repository/pbnOpen/dataUkpKirim'
import dataUkpNote from '@/repositories/repository/pbnOpen/dataUkpNote'
import dataUkpNum from '@/repositories/repository/pbnOpen/dataUkpNum'
import dataVerifikasi from '@/repositories/repository/pbnOpen/dataVerifikasi'
import dataVerifikasiTemp from '@/repositories/repository/pbnOpen/dataVerifikasiTemp'
import refAgama from '@/repositories/repository/pbnOpen/refAgama'
import refCuti from '@/repositories/repository/pbnOpen/refCuti'
import refDiklat from '@/repositories/repository/pbnOpen/refDiklat'
import refDiklatCluster from '@/repositories/repository/pbnOpen/refDiklatCluster'
import refDiklatType from '@/repositories/repository/pbnOpen/refDiklatType'
import refEselon from '@/repositories/repository/pbnOpen/refEselon'
import refEselonSatu from '@/repositories/repository/pbnOpen/refEselonSatu'
import refGajiPokok from '@/repositories/repository/pbnOpen/refGajiPokok'
import refGolonganPangkat from '@/repositories/repository/pbnOpen/refGolonganPangkat'
import refGradeJenis from '@/repositories/repository/pbnOpen/refGradeJenis'
import refHubunganKeluarga from '@/repositories/repository/pbnOpen/refHubunganKeluarga'
import refHukuman from '@/repositories/repository/pbnOpen/refHukuman'
import refJabatan from '@/repositories/repository/pbnOpen/refJabatan'
import refJabatanBackup from '@/repositories/repository/pbnOpen/refJabatanBackup'
import refJabatanGolongan from '@/repositories/repository/pbnOpen/refJabatanGolongan'
import refJabatanJpm from '@/repositories/repository/pbnOpen/refJabatanJpm'
import refJenisDokumen from '@/repositories/repository/pbnOpen/refJenisDokumen'
import refJenisJpm from '@/repositories/repository/pbnOpen/refJenisJpm'
import refJenisSk from '@/repositories/repository/pbnOpen/refJenisSk'
import refJenisUkp from '@/repositories/repository/pbnOpen/refJenisUkp'
import refJpmBobot from '@/repositories/repository/pbnOpen/refJpmBobot'
import refJpmJabatan from '@/repositories/repository/pbnOpen/refJpmJabatan'
import refJpmNew from '@/repositories/repository/pbnOpen/refJpmNew'
import refJpmNewConversion from '@/repositories/repository/pbnOpen/refJpmNewConversion'
import refKabupaten from '@/repositories/repository/pbnOpen/refKabupaten'
import refKementerian from '@/repositories/repository/pbnOpen/refKementerian'
import refKodePos from '@/repositories/repository/pbnOpen/refKodePos'
import refKota from '@/repositories/repository/pbnOpen/refKota'
import refKppn from '@/repositories/repository/pbnOpen/refKppn'
import refKppnBase from '@/repositories/repository/pbnOpen/refKppnBase'
import refLayanan from '@/repositories/repository/pbnOpen/refLayanan'
import refLhkpn from '@/repositories/repository/pbnOpen/refLhkpn'
import refPendidikanBidang from '@/repositories/repository/pbnOpen/refPendidikanBidang'
import refPendidikanKonversi from '@/repositories/repository/pbnOpen/refPendidikanKonversi'
import refPensiunDokumen from '@/repositories/repository/pbnOpen/refPensiunDokumen'
import refPensiunPokok from '@/repositories/repository/pbnOpen/refPensiunPokok'
import refPensiunType from '@/repositories/repository/pbnOpen/refPensiunType'
import refPerubahanPangkat from '@/repositories/repository/pbnOpen/refPerubahanPangkat'
import refProvinsi from '@/repositories/repository/pbnOpen/refProvinsi'
import refPulau from '@/repositories/repository/pbnOpen/refPulau'
import refSatker from '@/repositories/repository/pbnOpen/refSatker'
import refSeleksi from '@/repositories/repository/pbnOpen/refSeleksi'
import refStatusKawin from '@/repositories/repository/pbnOpen/refStatusKawin'
import refStatusPegawai from '@/repositories/repository/pbnOpen/refStatusPegawai'
import refTalentDokumen from '@/repositories/repository/pbnOpen/refTalentDokumen'
import refTingkatPendidikan from '@/repositories/repository/pbnOpen/refTingkatPendidikan'
import refUkp from '@/repositories/repository/pbnOpen/refUkp'
import refUkpDokumen from '@/repositories/repository/pbnOpen/refUkpDokumen'
import refUkpLokasi from '@/repositories/repository/pbnOpen/refUkpLokasi'
import refUnit from '@/repositories/repository/pbnOpen/refUnit'
import refUnitBase from '@/repositories/repository/pbnOpen/refUnitBase'
import refUnitGrade from '@/repositories/repository/pbnOpen/refUnitGrade'
import refUnitKonversi from '@/repositories/repository/pbnOpen/refUnitKonversi'

const repositories = {
  userData,
  dataAlamat,
  dataAlamatPensiun,
  dataCuti,
  dataCutiNote,
  dataCutiNum,
  dataCutiStatus,
  dataDiklat,
  dataDp3,
  dataDp3Detil,
  dataEdu,
  dataEduLast,
  dataEduProgress,
  dataEduReview,
  dataEmergency,
  dataEmp,
  dataFileDossier,
  dataFileUpload,
  dataHeader,
  dataIdCard,
  dataJabatan,
  dataKeluarga,
  dataKeluargaLajang,
  dataKgbDokumen,
  dataKgbHeaderFooter,
  dataKgbNum,
  dataKgbPropose,
  dataNama,
  dataPangkat,
  dataPangkatInpassing,
  dataPendi,
  dataPenghargaan,
  dataPic,
  dataPlacementReq,
  dataPropose,
  dataProposeDelete,
  dataProposeDiklat,
  dataProposeEdu,
  dataProposeEmp,
  dataProposeHukuman,
  dataProposeJabatan,
  dataProposeKeluarga,
  dataProposeDp3Detil,
  dataProposePangkat,
  dataProposePenghargaan,
  dataProposeReject,
  dataProposeSeminar,
  dataProposeSlks,
  dataProposeStatus,
  dataProposeUser,
  dataReportDefault,
  dataReportType,
  dataReqruitment,
  dataRequestApproval,
  dataRequestBase,
  dataRequestDokInfo,
  dataRequestIjazah,
  dataRequestIzinLuarNegeri,
  dataRequestIzinLuarNegeriCancel,
  dataRequestKaris,
  dataSeminar,
  dataStatus,
  dataUkpCover,
  dataUkpDaerah,
  dataUkpDokumen,
  dataUkpFileUpload,
  dataUkpInfo,
  dataUkpJadi,
  dataUkpKirim,
  dataUkpNote,
  dataUkpNum,
  dataVerifikasi,
  dataVerifikasiTemp,
  refAgama,
  refCuti,
  refDiklat,
  refDiklatCluster,
  refDiklatType,
  refEselon,
  refEselonSatu,
  refGajiPokok,
  refGolonganPangkat,
  refGradeJenis,
  refHubunganKeluarga,
  refHukuman,
  refJabatan,
  refJabatanBackup,
  refJabatanGolongan,
  refJabatanJpm,
  refJenisDokumen,
  refJenisJpm,
  refJenisSk,
  refJenisUkp,
  refJpmBobot,
  refJpmJabatan,
  refJpmNew,
  refJpmNewConversion,
  refKabupaten,
  refKementerian,
  refKodePos,
  refKota,
  refKppn,
  refKppnBase,
  refLayanan,
  refLhkpn,
  refPendidikanBidang,
  refPendidikanKonversi,
  refPensiunDokumen,
  refPensiunPokok,
  refPensiunType,
  refPerubahanPangkat,
  refProvinsi,
  refPulau,
  refSatker,
  refSeleksi,
  refStatusKawin,
  refStatusPegawai,
  refTalentDokumen,
  refTingkatPendidikan,
  refUkp,
  refUkpDokumen,
  refUkpLokasi,
  refUnit,
  refUnitBase,
  refUnitGrade,
  refUnitKonversi,
}
export default {
  get: name => repositories[name],
}
