export default [
  // layanan-karpeg
  {
    path: '/layanan/karpeg',
    name: 'layanan-karpeg',
    component: () => import('@/views/layanan/Karpeg.vue'),
    meta: {
      pageTitle: 'Layanan',
      breadcrumb: [
        {
          text: 'Pengajuan > Kartu Pegawai',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // layanan-karsu-karis
  {
    path: '/layanan/karsu-karis',
    name: 'layanan-karsu-karis',
    component: () => import('@/views/layanan/KarsuKaris.vue'),
    meta: {
      pageTitle: 'Layanan',
      breadcrumb: [
        {
          text: 'Pengajuan > Kartu Suami/Istri',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // layanan-izin-luar-negeri
  {
    path: '/layanan/izin-luar-negeri',
    name: 'layanan-izin-luar-negeri',
    component: () => import('@/views/layanan/IzinLuarNegeri.vue'),
    meta: {
      pageTitle: 'Layanan',
      breadcrumb: [
        {
          text: 'Pengajuan > Izin Luar Negeri',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // layanan-legalisir-ijazah
  {
    path: '/layanan/legalisir-ijazah',
    name: 'layanan-legalisir-ijazah',
    component: () => import('@/views/layanan/LegalisirIjazah.vue'),
    meta: {
      pageTitle: 'Layanan',
      breadcrumb: [
        {
          text: 'Pengajuan > Legalisir Ijazah PKN-STAN',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
]
