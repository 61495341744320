export default [
  // dokumentasi-petunjuk-penggunaan
  {
    path: '/petunjuk-penggunaan',
    name: 'petunjuk-penggunaan',
    component: () => import('@/views/dokumentasi/PetunjukPenggunaan.vue'),
    meta: {
      pageTitle: 'Dokumentasi',
      breadcrumb: [
        {
          text: 'Petunjuk Penggunaan',
          active: true,
        },
      ],
      pegawai: false,
    },
  },
  // dokumentasi-bantuan
  {
    path: '/bantuan',
    name: 'bantuan',
    component: () => import('@/views/dokumentasi/Bantuan.vue'),
    meta: {
      pageTitle: 'Dokumentasi',
      breadcrumb: [
        {
          text: 'Bantuan',
          active: true,
        },
      ],
      pegawai: false,
    },
  },
]
