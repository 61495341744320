import Repository from '@/repositories/factories/pbnOpenFactory'

const userData = Repository.get('userData')

export default {
  state: {
    usersPbn: [],
  },

  getters: {
    isLoginPbn: state => !!state.usersPbn.uName,
    isPegawaiPbn: state => !!state.usersPbn.uNip,
    isAdminPbn: state => !!(state.usersPbn.uLevel === 99),
    isPengelolaPbn: state => !!(state.usersPbn.uLevel === 11),
    thisUsers: state => state.usersPbn,
  },

  actions: {
    async checkUser({ commit }, payload) {
      commit('LOAD_USER', await userData.getPayload(payload))
    },
    logoutUser({ commit }) {
      commit('UNLOAD_USER')
    },
  },

  mutations: {
    INITIALIZE_USER: state => {
      if (localStorage.getItem('users_storage')) {
        const data = JSON.parse(localStorage.getItem('users_storage'))
        console.log('storage', data)
        state.usersPbn = data
        console.log('INITIALIZE_USER state', state.usersPbn)
      }
    },
    LOAD_USER: (state, res) => {
      const isDataAvailable = res.data && res.data.resource.length
      const dataResource = isDataAvailable ? res.data.resource[0] : {
        error: {
          code: 404, context: null, message: 'Invalid Credentials', status_code: 404,
        },
      }
      localStorage.setItem('users_storage', JSON.stringify(dataResource))
      state.usersPbn = dataResource
    },
    UNLOAD_USER: state => {
      localStorage.removeItem('users_storage')
      state.usersPbn = []
    },
  },
}
