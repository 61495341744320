import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import main from './routes/main'
import pegawai from './routes/pegawai'
import laporan from './routes/laporan'
import usulan from './routes/usulan'
import layanan from './routes/layanan'
import dokumentasi from './routes/dokumentasi'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // router hris-djpb
    ...main,
    ...pegawai,
    ...laporan,
    ...usulan,
    ...layanan,
    ...dokumentasi,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        checklogin: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/components/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/components/error/Error403.vue'),
      meta: {

      },
    },
  ],
})

router.beforeResolve((to, from, next) => {
  console.log(to)
  console.log('router isLoginPbn', store.getters.isLoginPbn)
  console.log('router isPegawaiPbn', store.getters.isPegawaiPbn)
  if (to.matched.some(record => record.meta.checklogin)) {
    if (store.getters.isLoginPbn && store.getters.isPegawaiPbn) {
      next('/')
      return
    }
  }
  if (to.matched.some(record => record.meta.pegawai)) {
    if (store.getters.isLoginPbn && store.getters.isPegawaiPbn) {
      next()
      return
    }
    next('/login')
  }
  if (to.matched.some(record => record.meta.pengelola)) {
    if (store.getters.isLoginPbn && store.getters.isPengelolaPbn) {
      next()
      return
    }
    next('/error-403')
  }
  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
