import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'

const baseDomain = 'https://df1.setditjen-djpb.net'
const baseURL = `${baseDomain}/api/v2` // Incase of /api/v1
const apiKey = 'ac42fc008705c6760d9b9faf875fe36aa22bf82fa732d036a1b976c100a21451'
const token = JSON.parse(localStorage.getItem('session_storage')) || ''
const Client = axios.create({
  baseURL,
  headers: {
    'X-DreamFactory-API-Key': apiKey,
    'Cache-Control': 'no-cache',
  },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, true), 2 * 1000),
})

if (token.session_token) {
  Client.defaults.headers.common['X-DreamFactory-Session-Token'] = `${token.session_token}`
}

Client.interceptors.response.use(response => response, error => {
  switch (error.response.status) {
    case 400:
      console.error('400', error.response.status, error.message)
      break
    case 401:
      console.error('401', error.response.status, error.message)
      localStorage.removeItem('session_storage')
      localStorage.removeItem('users_storage')
      window.location.href = 'login'
      break
    case 403:
      console.error('403', error.response.status, error.message)
      localStorage.removeItem('session_storage')
      localStorage.removeItem('users_storage')
      window.location.href = 'login'
      break
    default:
      console.error(error.response.status, error.message)
  }
  return Promise.reject(error)
})

export default Client
