import Repository from '@/repositories/factories/sessionFactory'
import { setHeaderTokenDreamfactory, removeHeaderTokenDreamfactory } from '@/utils/auth'

const SessionRepository = Repository.get('sessions')

const user = 'hris_user@setditjen-djpb.net'
const password = 'hris_user!#'
const defaultSession = {
  email: `${user}`,
  password: `${password}`,
  duration: 0,
}

export default {
  state: {
    sessions: {},
  },
  getters: {
    isAuthenticated: state => !!state.sessions.session_token,
    isSysAdmin: state => state.sessions.is_sys_admin,
    thisSessions: state => state.sessions,
  },
  actions: {
    async getSession({ commit }) {
      commit('LOAD_SESSIONS', await SessionRepository.get())
    },
    async postSession({ commit }) {
      commit('LOAD_SESSIONS', await SessionRepository.create(defaultSession))
    },
    async refreshSession({ commit }) {
      commit('LOAD_SESSIONS', await SessionRepository.update())
    },
    async deleteSession({ commit }) {
      commit('UNLOAD_SESSIONS', await SessionRepository.delete())
    },
  },

  mutations: {
    INITIALIZE_SESSIONS: state => {
      if (localStorage.getItem('session_storage')) {
        const data = JSON.parse(localStorage.getItem('session_storage'))
        state.sessions = data
      }
    },
    LOAD_SESSIONS: (state, res) => {
      const { data } = res
      state.sessions = data
      // localStorage.setItem('session_token', data.session_token)
      localStorage.setItem('session_storage', JSON.stringify(data))
      setHeaderTokenDreamfactory(data.session_token)
    },
    UNLOAD_SESSIONS: (state, res) => {
      const { data } = res
      state.sessions = data
      // localStorage.removeItem('session_token')
      localStorage.removeItem('session_storage')
      removeHeaderTokenDreamfactory()
    },

  },
}
