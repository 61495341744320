export default [
  // home
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // unit-kerja
  {
    path: '/unit-kerja',
    name: 'unit-kerja',
    component: () => import('@/views/UnitKerja.vue'),
    meta: {
      pageTitle: 'Unit Kerja',
      breadcrumb: [
        {
          text: 'Unit Kerja',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
]
