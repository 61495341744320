import Client from '@/repositories/endpoints/hrisApi'

const path = '/hris_djpb'
const resource = '/_proc/pegawai_profile'
const request = `${path}${resource}`

export default {
  callproc(payload) {
    const param = {
      params: [{
        name: 'v_nip',
        param_type: 'IN',
        value: payload,
        type: 'varchar',
        length: 18,
      }],
      schema: {
        _field_name_: '',
      },
      wrapper: 'resource',
      returns: '',
    }
    return Client.post(`${request}`, param)
  },
  // MANY OTHER ENDPOINT RELATED STUFFS
}
