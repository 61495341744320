import user from '@/repositories/repository/hrisDjpb/user'
import displayUnit from '@/repositories/repository/hrisDjpb/displayUnit'
import displayUnitKodeSatker from '@/repositories/repository/hrisDjpb/displayUnitKodeSatker'
import displayUnitPegawai from '@/repositories/repository/hrisDjpb/displayUnitPegawai'
import displayUnitPegawaiFoto from '@/repositories/repository/hrisDjpb/displayUnitPegawaiFoto'
import displayPegawaiDjpb from '@/repositories/repository/hrisDjpb/displayPegawaiDjpb'
import displayPegawaiDjpbKodeSatker from '@/repositories/repository/hrisDjpb/displayPegawaiDjpbKodeSatker'
import refAbkKantor from '@/repositories/repository/hrisDjpb/refAbkKantor'
import refConfigPbnOpen from '@/repositories/repository/hrisDjpb/refConfigPbnOpen'
import refEselon1 from '@/repositories/repository/hrisDjpb/refEselon1'
import refEselon2 from '@/repositories/repository/hrisDjpb/refEselon2'
import refEselon3 from '@/repositories/repository/hrisDjpb/refEselon3'
import refEselon4 from '@/repositories/repository/hrisDjpb/refEselon4'
import refFotoPegawai from '@/repositories/repository/hrisDjpb/refFotoPegawai'
import refKantor from '@/repositories/repository/hrisDjpb/refKantor'
import refKantorPbnOpen from '@/repositories/repository/hrisDjpb/refKantorPbnOpen'
import refLevel from '@/repositories/repository/hrisDjpb/refLevel'
import refMappingUnitKodeSatker from '@/repositories/repository/hrisDjpb/refMappingUnitKodeSatker'
import refUnit from '@/repositories/repository/hrisDjpb/refUnit'
import pegawaiProfile from '../repository/hrisDjpb/pegawaiProfile'
import pegawaiGrade from '../repository/hrisDjpb/pegawaiGrade'

const repositories = {
  user,
  displayUnit,
  displayUnitKodeSatker,
  displayUnitPegawai,
  displayUnitPegawaiFoto,
  displayPegawaiDjpb,
  displayPegawaiDjpbKodeSatker,
  refAbkKantor,
  refConfigPbnOpen,
  refEselon1,
  refEselon2,
  refEselon3,
  refEselon4,
  refFotoPegawai,
  refKantor,
  refKantorPbnOpen,
  refLevel,
  refMappingUnitKodeSatker,
  refUnit,
  pegawaiProfile,
  pegawaiGrade,
}
export default {
  get: name => repositories[name],
}
