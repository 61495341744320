export default [
  // pegawai-profile
  {
    path: '/pegawai-profile',
    name: 'pegawai-profile',
    component: () => import('@/views/components/ProfilePegawai.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-kehadiran
  {
    path: '/pegawai-kehadiran',
    name: 'pegawai-kehadiran',
    component: () => import('@/views/pegawai/Kehadiran.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Kehadiran',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-jabatan
  {
    path: '/pegawai/jabatan',
    name: 'pegawai-jabatan',
    component: () => import('@/views/pegawai/Jabatan.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Jabatan',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-pangkat
  {
    path: '/pegawai/pangkat',
    name: 'pegawai-pangkat',
    component: () => import('@/views/pegawai/Pangkat.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Pangkat',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-keluarga
  {
    path: '/pegawai/keluarga',
    name: 'pegawai-keluarga',
    component: () => import('@/views/pegawai/Keluarga.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Keluarga',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-alamat
  {
    path: '/pegawai/alamat',
    name: 'pegawai-alamat',
    component: () => import('@/views/pegawai/Alamat.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Alamat',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-status-kepegawaian
  {
    path: '/pegawai/status-kepegawaian',
    name: 'pegawai-status-kepegawaian',
    component: () => import('@/views/pegawai/StatusKepegawaian.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Status Kepegawaian',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-pendidikan
  {
    path: '/pegawai/pendidikan',
    name: 'pegawai-pendidikan',
    component: () => import('@/views/pegawai/Pendidikan.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Pendidikan',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-diklat
  {
    path: '/pegawai/diklat',
    name: 'pegawai-diklat',
    component: () => import('@/views/pegawai/Diklat.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Diklat',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // pegawai-penghargaan
  {
    path: '/pegawai/penghargaan',
    name: 'pegawai-penghargaan',
    component: () => import('@/views/pegawai/Penghargaan.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Pegawai > Penghargaan',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
]
