import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import hrisDjpb from '@/store/endpoints/hrisDjpb/store'
import pbnOpen from '@/store/endpoints/pbnOpen/store'
import session from '@/store/endpoints/sessionEndpoints'

Vue.use(Vuex)
const myVuex = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    hrisDjpb,
    pbnOpen,
    session,
  },
  strict: process.env.DEV,
})

export default myVuex
