import Client from '@/repositories/endpoints/hrisApi'

const path = '/hris_djpb'
const resource = '/_table/ref_abk_kantor'
const request = `${path}${resource}`

export default {
  get() {
    return Client.get(`${request}`)
  },
  getItem(id) {
    return Client.get(`${request}/${id}`)
  },
  getPayload(payload) {
    return Client.get(`${request}?filter=${payload}`)
  },
  // MANY OTHER ENDPOINT RELATED STUFFS
}
