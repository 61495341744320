import Client from '@/repositories/endpoints/hrisApi'

const resource = '/user/session'

export default {
  get() {
    return Client.get(`${resource}`)
  },
  create(payload) {
    return Client.post(`${resource}`, payload)
  },
  update() {
    return Client.put(`${resource}`)
  },
  delete() {
    return Client.delete(`${resource}`)
  },

  // MANY OTHER ENDPOINT RELATED STUFFS
}
