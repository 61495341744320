import axios from '@/repositories/endpoints/hrisApi'

export function setHeaderToken(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

export function removeHeaderToken() {
  delete axios.defaults.headers.common.Authorization
}

export function setHeaderTokenDreamfactory(token) {
  axios.defaults.headers.common['X-DreamFactory-Session-Token'] = `${token}`
}

export function removeHeaderTokenDreamfactory() {
  delete axios.defaults.headers.common['X-DreamFactory-Session-Token']
}
