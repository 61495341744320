export default [
  // laporan-riwayat-hidup
  {
    path: '/laporan/riwayat-hidup',
    name: 'laporan-riwayat-hidup',
    component: () => import('@/views/laporan/RiwayatHidup.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Laporan > Riwayat Hidup',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // laporan-kp4
  {
    path: '/laporan/kp4',
    name: 'laporan-kp4',
    component: () => import('@/views/laporan/KP4.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Laporan > KP4',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // laporan-perubahan-data
  {
    path: '/laporan/perubahan-data',
    name: 'laporan-perubahan-data',
    component: () => import('@/views/laporan/PerubahanData.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Laporan > Perubahan Data',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // laporan-daftar-unit-kerja
  {
    path: '/laporan/daftar-unit-kerja',
    name: 'laporan-daftar-unit-kerja',
    component: () => import('@/views/laporan/DaftarUnitKerja.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Laporan > Daftar Unit Kerja',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
  // laporan-daftar-pegawai
  {
    path: '/laporan/daftar-pegawai',
    name: 'laporan-daftar-pegawai',
    component: () => import('@/views/laporan/DaftarPegawai.vue'),
    meta: {
      pageTitle: 'Administrasi',
      breadcrumb: [
        {
          text: 'Laporan > Daftar Pegawai',
          active: true,
        },
      ],
      pegawai: true,
    },
  },
]
