import Client from '@/repositories/endpoints/hrisApi'

const path = '/pbn_emp'
const resource = '/_table/dt_propose_pangkat'
const request = `${path}${resource}`

export default {
  get() {
    return Client.get(`${request}`)
  },
  getItem(id) {
    return Client.get(`${request}/${id}`)
  },
  getPayload(payload) {
    return Client.get(`${request}?filter=${payload}`)
  },
  create(payload) {
    return Client.post(`${request}`, payload)
  },
  update(payload, id) {
    return Client.put(`${request}/${id}`, payload)
  },
  delete(id) {
    return Client.delete(`${request}/${id}`)
  },

  // MANY OTHER ENDPOINT RELATED STUFFS
}
